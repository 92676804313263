import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import "./assets/tailwind.css";

//Chefinho Components
import TheHeader from "@/components/partials/TheHeader.vue";
import Card from "@/components/Card.vue";
import TheContainer from "@/components/TheContainer.vue";
import TheInput from "@/components/forms/TheInput.vue";
import TheButton from "@/components/forms/TheButton.vue";
import TheSaveButtons from "@/components/forms/TheSaveButtons.vue";
import ErrorHandler from "@/components/helper/ErrorHandler.vue";

import Primevue from "primevue/config";
import "primevue/resources/themes/lara-light-amber/theme.css"; //theme
import "primeicons/primeicons.css"; //icons

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";

const app = createApp(App)
  .use(Primevue, {
    ripple: true,
    unstyled: false,
    pt: {
      InputText: {
        root: "py-2 px-3 rounded border-gray-400/50 border-2 focus:border-transparent focus:ring-2 focus: ring-orange-300 focus:outline-none placeholder-slate-400 shadow-md",
      },
      DataTable: {
        header: "bg-orange-300/50 text-white",
      },
      Column: {
        headerCell: "bg-orange-300 text-white",
      },
    },
  })
  .use(router)
  .use(store)
  .use(ToastService)
  .use(ConfirmationService);

//Chefinho Components
app.component("TheHeader", TheHeader);
app.component("TheContainer", TheContainer);
app.component("TheInput", TheInput);
app.component("TheSaveButtons", TheSaveButtons);
app.component("TheButton", TheButton);
app.component("Card", Card);
app.component("ErrorHandler", ErrorHandler);

// PrimeVue components
app.component("InputText", InputText);
app.component("Tag", Tag);
app.component("Button", Button);
app.component("TriStateCheckbox", TriStateCheckbox);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ConfirmPopup", ConfirmPopup);

app.mount("#app");
