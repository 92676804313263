<template>
  <div class="general-padding-x pt-3" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TheContainer",
  props: {
    className: String,
  },
  setup(props) {
    return {};
  },
};
</script>
