<template>
  <Toast />
</template>

<script>
import { ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { errorHandler } from "@/utils/errorHandler";
export default {
  name: "ErrorHandler",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const toast = useToast();
    const errors = ref(props.errors);

    watch(
      () => props.errors,
      (value) => {
        const errors = errorHandler(value);

        for (let i = 0; i < errors.length; i++) {
          toast.add({
            severity: "error",
            summary: "Error Message",
            detail: errors[i][Object.keys(errors[i])],
            life: 5000,
          });
        }
      }
    );
    return {
      errors,
    };
  },
};
</script>
