<template>
  <h3 class="text-2xl font-bold" v-if="title">{{ title }}</h3>
  <div class="card p-2 bg-orange-50 shadow-md rounded-md" :class="className">
    <div class="slot relative">
      <div
        v-if="loading"
        class="absolute inset-0 w-full h-full bg-black/30 flex justify-center pt-12"
      >
        <div class="flex items-center space-x-2 text-white text-2xl">
          <i class="pi pi-spin pi-spinner"></i>
          <span>{{ loadingLabel }}</span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Card",
  props: {
    title: String,
    col: Number,
    loading: {
      type: Boolean,
      default: false,
    },
    loadingLabel: {
      type: String,
      default: "Loading data...",
    },
    className: String,
  },
  setup(props) {
    const columns = computed(() => {
      return props.col ? `w-${props.col}/12` : "w-full";
    });

    return { columns };
  },
};
</script>
