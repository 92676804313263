import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import js_cookie from "js-cookie";

function applyMiddleware(routes, middleware) {
  return routes.map((route) => {
    return {
      ...route,
      meta: { ...route.meta, middleware },
    };
  });
}

const authRoutes = applyMiddleware(
  [
    {
      path: "/",
      name: "home",
      meta: { title: "Home" },
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/HomeView.vue"),
    },
    {
      path: "/companies",
      name: "Companies",
      meta: { title: "Lista de restaurantes" },
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/Companies/Companies.vue"
        ),
    },
    {
      path: "/companies/add",
      name: "AddCompany",
      meta: { title: "Criando restaurante" },
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Companies/Company.vue"),
    },
    {
      path: "/companies/:id",
      name: "EditCompany",
      meta: { title: "Editando restaurante" },
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Companies/Company.vue"),
    },
  ],
  "auth"
);

const guestRoutes = applyMiddleware(
  [
    {
      path: "/login",
      name: "login",
      meta: { title: "Login" },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
    },
    {
      path: "/register",
      name: "register",
      meta: { title: "Register" },
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/RegisterUserView.vue"),
    },
  ],
  "guest"
);

const routes = [...authRoutes, ...guestRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;

  if (to.meta.middleware == "guest") {
    if (js_cookie.get("token")) {
      next({ name: "home" });
    }
    next();
  } else {
    if (js_cookie.get("token")) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
