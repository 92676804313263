export const errorHandler = (error, message) => {
  let errorsList = [];

  if (error.response.data.errors) {
    errorsList = error.response.data.errors;
  } else if (error.response.data.message) {
    errorsList = {
      message: [error.response.data.message],
    };
  } else {
    errorsList = {
      message: [message],
    };
  }

  if (typeof errorsList === "object" && !Array.isArray(errorsList)) {
    errorsList = Object.keys(errorsList).map((key) => errorsList[key]);
  }

  return errorsList;
};
