<template>
  <div class="px-2">
    <button
      :type="type"
      class="bg-orange-500 text-white tracking-wide rounded-sm shadow-md p-2 w-full"
      :class="className"
      :disabled="disabled || loading"
    >
      <div v-if="loading" class="flex items-center justify-center space-x-2">
        <CIcon :icon="cilSync" customClassName="w-6 animate-spin" />
        <span>{{ loadingText }}</span>
      </div>
      <span v-else><i :class="icon" v-if="icon"></i> {{ label }}</span>
    </button>
  </div>
</template>

<script>
import { computed } from "vue";
import { CIcon } from "@coreui/icons-vue";
import { cilSync } from "@coreui/icons";

export default {
  name: "TheButton",
  props: {
    label: String,
    icon: String,
    className: String,
    type: {
      type: String,
      default: "submit",
    },
    disabled: Boolean,
    loading: Boolean,
    loadingText: String,
  },
  components: {
    CIcon,
  },
  setup(props) {
    const loadingText = computed(() => {
      return props.loadingText || "Loading...";
    });

    return {
      loadingText,
      cilSync,
    };
  },
};
</script>
