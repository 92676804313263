<template>
  <header class="bg-amber-200 relative">
    <div
      class="lg:container lg:mx-auto flex justify-between items-center general-padding-x"
    >
      <span
        @click="toggleMenu()"
        class="menu_icon w-12 sm:hidden cursor-pointer hover:bg-amber-300"
      >
        <svg
          viewBox="0 -0.5 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5 7.75C5.08579 7.75 4.75 8.08579 4.75 8.5C4.75 8.91421 5.08579 9.25 5.5 9.25V7.75ZM19.5 9.25C19.9142 9.25 20.25 8.91421 20.25 8.5C20.25 8.08579 19.9142 7.75 19.5 7.75V9.25ZM5.5 11.75C5.08579 11.75 4.75 12.0858 4.75 12.5C4.75 12.9142 5.08579 13.25 5.5 13.25V11.75ZM17.5 13.25C17.9142 13.25 18.25 12.9142 18.25 12.5C18.25 12.0858 17.9142 11.75 17.5 11.75V13.25ZM5.5 15.75C5.08579 15.75 4.75 16.0858 4.75 16.5C4.75 16.9142 5.08579 17.25 5.5 17.25V15.75ZM12.5 17.25C12.9142 17.25 13.25 16.9142 13.25 16.5C13.25 16.0858 12.9142 15.75 12.5 15.75V17.25ZM5.5 9.25H19.5V7.75H5.5V9.25ZM5.5 13.25H17.5V11.75H5.5V13.25ZM5.5 17.25H12.5V15.75H5.5V17.25Z"
            fill="#000000"
          />
        </svg>
      </span>
      <div class="logo flex items-center py-2">
        <img src="https://i.imgur.com/nwYaG4g.png" class="w-11" alt="Logo" />
        <span class="font-bold text-2xl pl-2 font-serif">CHEFINHO</span>
      </div>
      <div
        class="user_header flex relative"
        @click="showUserOptions($event)"
        @mouseout="showUserOptions($event)"
      >
        <div class="user cursor-pointer">
          <img
            src="https://i.imgur.com/9uI7MPg.png"
            class="w-12 h-12 rounded-md"
            alt="User"
          />
        </div>

        <div
          class="user_options absolute z-10 right-0 top-14 bg-white rounded-md shadow-md"
          v-show="userOptions"
        >
          <ul class="text-black">
            <li
              class="py-2 px-4 hover:bg-gray-200 rounded-md cursor-pointer"
              @click="logout()"
            >
              Sair
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="border-b-2 border-orange-300"></div>
    <div
      :class="{ hidden: !showMenu }"
      class="sm:general-padding-x sm:flex lg:container lg:mx-auto shadow-lg sm:shadow-sm"
    >
      <nav
        class="w-full sm:flex block absolute sm:relative h-auto sm:h-14 text-black font-mono bg-amber-200"
      >
        <router-link
          class="transition h-full flex items-center px-3"
          :class="{ 'nav-option-active': $route.path === '/' }"
          to="/"
        >
          Home
        </router-link>
        <router-link
          class="transition nav-option"
          :class="{
            'nav-option-active': $route.path.indexOf('/companies') > -1,
          }"
          to="/companies"
        >
          Restaurantes
        </router-link>
        <router-link
          class="transition nav-option"
          :class="{ 'nav-option-active': $route.path === '/login' }"
          to="/login"
        >
          Receitas
        </router-link>
        <router-link
          class="transition nav-option"
          :class="{ 'nav-option-active': $route.path === '/login' }"
          to="/login"
        >
          Ingredientes
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    //Declare variables
    let showMenu = ref(false);
    let userOptions = ref(false);
    let store = useStore();

    //methods
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const showUserOptions = ($event) => {
      if (userOptions.value) {
        setTimeout(() => {
          userOptions.value = !userOptions.value;
        }, 500);
      } else {
        userOptions.value = !userOptions.value;
      }
    };

    const logout = () => {
      store.dispatch("auth/logout");
    };

    //returns
    return {
      showMenu,
      toggleMenu,
      userOptions,
      showUserOptions,
      logout,
    };
  },
};
</script>
