<template>
  <div class="flex flex-col px-2 mb-2">
    <label class="mb-1" for="">{{ label }}</label>
    <input @input="emitValue()" v-bind="$attrs" :value="modelValue" :placeholder="defaultPlaceholder" :class="className" class="py-2 px-3 rounded border-gray-400/50 border-2 focus:border-transparent focus:ring-2 focus: ring-orange-300 focus:outline-none placeholder-slate-400 shadow-md" />
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "TheInput",
  props: {
    label: String,
    placeholder: String,
    className: String,
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  setup(props, { emit }) {
    const defaultPlaceholder = computed(() => {
      return props.placeholder ? props.placeholder : props.label;
    });

    const emitValue = () => {
      emit("update:modelValue", event.target.value);
    };

    return {
      defaultPlaceholder,
      emitValue,
    };
  },
};
</script>
