import router from "@/router";
import js_cookie from "js-cookie";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
  },
  actions: {
    login({ commit }, { token, user }) {
      js_cookie.set("token", token);
      commit("SET_USER", user);
      commit("SET_AUTHENTICATED", true);
      router.push({ name: "Companies" });
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
      js_cookie.remove("token");
      router.push({ name: "login" });
    },
  },
};
