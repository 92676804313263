<template>
  <ErrorHandler :errors="errors" />
  <div class="flex justify-end mt-4">
    <TheButton :label="labelSave" :className="className" @click="save()" />
    <TheButton :label="labelCancel" className="btn-secondary" @click="cancel" />
  </div>
</template>

<script>
import { useRouter } from "vue-router"; // Import useRouter
import { useToast } from "primevue/usetoast";
import { ref } from "vue";

export default {
  name: "TheSaveButtons",
  props: {
    labelSave: {
      type: String,
      default: "Salvar",
    },
    labelCancel: {
      type: String,
      default: "Cancelar",
    },
    className: {
      type: String,
      default: "btn-info",
    },
    funcSave: {
      type: Function,
      default: () => {},
    },
    funcCancel: {
      type: String,
      default: "/",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter(); // Use useRouter to get the current route object
    const toast = useToast();
    const errors = ref([]);

    const cancel = () => {
      router.push({ name: props.funcCancel }); // Use router.push to navigate to the route
    };

    const save = async () => {
      try {
        await props.funcSave().then(() => {
          router.push({ name: props.funcCancel });
        });
      } catch (error) {
        console.log(error);
        errors.value = error;
      }
      emit("update:loading", false);
    };

    return {
      cancel,
      save,
      errors,
    };
  },
};
</script>
